/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Aug 04 2022
 */

import axios from 'axios';
import {
  defineStore,
} from 'pinia';
import {
  abbreviateName,
  profileImageSrc,
} from '@/helpers';
import {
  IUser,
} from '@/type';

async function getLoggedInUser(token: string): Promise<IUser | undefined> {
  try {
    const resp = await axios.get('/users/this', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data.user;
  } catch (e: any) {
    console.error(e);
    return undefined;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: undefined as IUser | undefined,
    token: '',
  }),
  getters: {
    usernameAbbreviation: (state) => abbreviateName(
      state.user?.firstName || state.user?.username,
      state.user?.lastName,
    ),
    userProfileImageSrc: async (state) => {
      const profileImage = await profileImageSrc(state.user);
      return profileImage;
    },
  },
  actions: {
    async refreshUser() {
      const user = await getLoggedInUser(this.token);
      this.user = user;
    },
    setAuthInfo(token: string, user?: IUser) {
      this.user = user;
      this.token = token;

      if (window.localStorage.getItem('TABBED_REMEMBER_ME')) {
        window.sessionStorage.setItem('token', token);
      } else {
        window.localStorage.setItem('token', token);
      }
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
    async login(token: string) {
      const user = await getLoggedInUser(token);
      this.setAuthInfo(token, user);
    },
    async localLogin() {
      const tabbedRememberMe = window.localStorage.getItem('TABBED_REMEMBER_ME');
      const token = tabbedRememberMe
        ? window.sessionStorage.getItem('token')
        : window.localStorage.getItem('token');
      if (!token) {
        return;
      }

      const user = await getLoggedInUser(token);
      this.setAuthInfo(token, user);
    },
    logout() {
      this.setAuthInfo('', undefined);
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
