const CONSTANTS = {
  ROUTES: {
    GUEST_HOME: '/auth/login',
    ADMIN_HOME: '/admin/user-list',
    COURSE_HOME: '/admin/course-list',
    ACCOUNT_PAGE: '/user/account',
  } as const,
} as const;

export default CONSTANTS;
