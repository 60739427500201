import axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosRequestConfig,
} from 'axios';
import {
  getAppInfo,
} from 'utilities';
import CONSTANTS from './constants';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import {
  baseServerUrl,
} from '@/configs/client.config.json';
import {
  IDevice,
  IUser,
  IUserRole,
} from '@/type';

export { default as CONSTANTS } from './constants';

export const {
  SERVER_PORT,
  CLIENT_PORT,
  IS_DEVELOPMENT,
  CONFIG,
  ROUTE_API,
} = getAppInfo();

const { protocol, hostname } = window.location;

// for local testing, baseServerUrl = https://localhost:3000 can be set, if necessary
export const baseUrl = baseServerUrl || (IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${SERVER_PORT}`
  : window.location.origin);

export const clientBaseUrl = IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${CLIENT_PORT}`
  : window.location.origin;

export function abbreviateName(firstName?: string, lastName?: string): string {
  if (firstName && lastName) {
    return (firstName[0] + lastName[0]).toUpperCase();
  }
  return (firstName || lastName)?.toUpperCase() || 'N/A';
}

export function viewportBreakpoint(): string {
  const width = window.innerWidth;
  let breakpoint = 'xs';

  if (width >= 640) {
    breakpoint = 'sm';
  }
  if (width >= 768) {
    breakpoint = 'md';
  }
  if (width >= 1024) {
    breakpoint = 'lg';
  }
  if (width >= 1280) {
    breakpoint = 'xl';
  }
  if (width >= 1536) {
    breakpoint = '2xl';
  }
  return breakpoint;
}

export function getFileSrc(
  fileName: string,
  local = false,
  url = false,
): string {
  if (!fileName.length || fileName.startsWith('http')) {
    return fileName;
  }

  let fullPath: string;
  if (fileName.includes('/')) {
    // Split and encode only if there's a directory path
    const pathParts = fileName.split('/');
    const encodedFileName = encodeURIComponent(pathParts.pop() || '');
    const directoryPath = pathParts.join('/');
    fullPath = `${directoryPath}/${encodedFileName}`;
  } else {
    // Encode the file name directly if there's no directory
    fullPath = encodeURIComponent(fileName);
  }

  // Construct the URL parameters with the full path
  const params = new URLSearchParams({
    fileName: fullPath,
    ...(local && {
      local: '1',
    }),
    ...(url && {
      url: '1',
    }),
  });

  const fileUrl = `${baseUrl}${ROUTE_API}/files?${params.toString()}`;
  return fileUrl;
}

export function convertBlobUrlToDataUrl(blobUrl: string): Promise<string> {
  return fetch(blobUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      return response.blob();
    })
    .then((blob) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = (): void => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('FileReader did not produce a string.'));
        }
      };
      reader.onerror = (error): void => reject(error);
      reader.readAsDataURL(blob);
    }));
}

export async function profileImageSrc(user?: IUser): Promise<string> {
  // if there is no profile image
  if (!user?.profileImage) {
    return '';
  }

  const { profileImage } = user;

  // if profileImage is stored in server
  if (!profileImage.startsWith('http')) {
    return getFileSrc(profileImage);
  }

  // if profile image is remote url

  const fbId = new URL(profileImage).searchParams.get('asid');

  // if profile image comes from fb
  if (fbId) {
    const response = await fetch(profileImage);
    // if current fb profile image is present return it
    if (response.status === 200) {
      return profileImage;
    }
    // returns default fb profile image
    return `https://graph.facebook.com/${fbId}/picture?type=normal`;
  }
  // if profileImage is remote url but not from fb
  return profileImage;
}

export function getFileSignedUrl(
  { fileName, method, size, isPublic }: {
    fileName: string;
    method: 'get' | 'put';
    size?: number;
    isPublic?: boolean
  },
): string {
  let fullPath;
  if (fileName.includes('/')) {
    // Split and encode only if there's a directory path
    const pathParts = fileName.split('/');
    const encodedFileName = encodeURIComponent(pathParts.pop() || '');
    const directoryPath = pathParts.join('/');
    fullPath = `${directoryPath}/${encodedFileName}`;
  } else {
    // Encode the file name directly if there's no directory
    fullPath = encodeURIComponent(fileName);
  }

  // Construct the URL parameters
  const params = new URLSearchParams({
    fileName: fullPath,
    method,
    ...(size !== undefined && {
      size: size.toString(),
    }),
    ...(isPublic && {
      isPublic: '1',
    }),
  });

  const url = `/files/file-signed-url?${params.toString()}`;
  return url;
}

export function handleAxiosError<T extends {
  message: string;
  errors?: Map<string, string> | Record<string, string | undefined>;
}>(
  e: AxiosError<T>,
  defaultMessage = 'Sorry, an error occurred',
  showAdditionalError = true,
): void {
  console.error(e);

  if (!e.isAxiosError || !e?.response?.data) {
    Bus.emit(NOTIFICATION.ERROR, {
      message: defaultMessage,
      permanent: true,
    });
    return;
  }

  let message = '';
  let errorMessages = '';

  if (typeof e.response.data === 'string') {
    message = e.response.data;
  } else if (e.response.data.message) {
    message = e.response.data.message;
  } else {
    message = defaultMessage || e.response.statusText;
  }

  if (e.response.data.errors && showAdditionalError) {
    const { errors } = e.response.data;
    for (const key of Object.keys(errors)) {
      errorMessages += `, ${key}: `;
      if (errors instanceof Map) {
        errorMessages += errors.get(key);
      } else {
        errorMessages += errors[key];
      }
    }
  }

  const finalMessage = message + errorMessages;

  Bus.emit(NOTIFICATION.ERROR, {
    message: finalMessage,
    permanent: true,
  });
}

export function identifyDeviceType(): IDevice {
  let isMobile = false;
  if (
    /Android/i.test(navigator.userAgent)
    && /Mobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  } else if (/webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  } else if (window.innerWidth < 768) {
    isMobile = true;
  }

  const isTouchDevice = 'ontouchend' in document;
  const isChrome = /Chrome/i.test(navigator.userAgent) || /CriOS/i.test(navigator.userAgent);
  const isSafari = /Safari/i.test(navigator.userAgent) && !isChrome;
  const isFirefox = /Firefox/i.test(navigator.userAgent);
  const isMacintosh = /Macintosh/i.test(navigator.userAgent);
  const isMac = isMacintosh && !isTouchDevice;
  const isWindows = /Windows/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isAndroidTablet = /Android/i.test(navigator.userAgent)
    && !/Mobile/i.test(navigator.userAgent);
  const isLinux = /Linux/i.test(navigator.userAgent) && !isAndroid;
  const isIPad = /iPad/i.test(navigator.userAgent) || (isMacintosh && isTouchDevice);
  const isIPhone = /iPhone/i.test(navigator.userAgent);

  return {
    isMobile,
    isTouchDevice,
    isChrome,
    isSafari,
    isFirefox,
    isMac,
    isWindows,
    isAndroid,
    isLinux,
    isIPad,
    isIPhone,
    isAndroidTablet,
  };
}

export function redirectUserAfterLogin(
  user: IUser,
): { route: string } {
  const { role } = user;
  if (role) {
    return {
      route: role === 'course-admin' ? CONSTANTS.ROUTES.COURSE_HOME : CONSTANTS.ROUTES.ADMIN_HOME,
    };
  }

  // otherwise go to guest url
  return {
    route: CONSTANTS.ROUTES.GUEST_HOME,
  };
}

const ROUTE_ACCESS = {
  '/admin/user-list': ['root', 'site-admin'],
  '/admin/user/add': ['root', 'site-admin'],
  '/admin/user/edit': ['root', 'site-admin'],
  '/admin/course-list': ['course-admin'],
  '/admin/course/add': ['course-admin'],
  '/admin/course/edit': ['course-admin'],
  '/admin/instructor-list': ['course-admin'],
  '/admin/instructor/add': ['course-admin'],
  '/admin/instructor/edit': ['course-admin'],
  '/admin/enrollment-list': ['course-admin', 'site-admin'],
  '/admin/student-list': ['course-admin', 'site-admin'],
  '/admin/material-list': ['course-admin'],
  '/admin/material/add': ['course-admin'],
  '/admin/material/edit': ['course-admin'],
  '/admin/coupon-list': ['course-admin', 'site-admin'],
  '/admin/coupon/add': ['course-admin', 'site-admin'],
  '/admin/coupon/edit': ['course-admin', 'site-admin'],
  '/admin/home-banner-list': ['course-admin', 'site-admin'],
  '/admin/home-banner/add': ['course-admin', 'site-admin'],
  '/admin/home-banner/edit': ['course-admin', 'site-admin'],
  '/admin/subject-list': ['course-admin'],
  '/admin/subject/add': ['course-admin'],
  '/admin/subject/edit': ['course-admin'],
  '/admin/youtube-course-list': ['course-admin'],
  '/admin/youtube-course/add': ['course-admin'],
  '/admin/youtube-course/edit': ['course-admin'],
  '/admin/news-list': ['course-admin', 'site-admin'],
  '/admin/news/add': ['course-admin', 'site-admin'],
  '/admin/news/edit': ['course-admin', 'site-admin'],
  '/admin/settings/facebook': ['course-admin'],
};

export function hasRouteAccess(routeName: string, userRole: IUserRole): boolean {
  const allowedRoles = ROUTE_ACCESS[routeName];
  // Default to true if no specific roles are defined
  return allowedRoles ? allowedRoles.includes(userRole) : true;
}

export async function fetchAsBlob(url: string): Promise<Blob> {
  const res = await fetch(url);
  return res.blob();
}

export async function download(content: string, filename: string): Promise<void> {
  Bus.emit(NOTIFICATION.SUCCESS, {
    message: `${filename} ফাইলটি ডাউনলোড হচ্ছে`,
  });

  try {
    const element = document.createElement('a');

    if (content.startsWith('http')) {
      const blob = await fetchAsBlob(content);
      const link = window.URL.createObjectURL(blob);
      element.setAttribute('href', link);
    } else {
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
    }

    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  } catch (e: unknown) {
    console.error('Error in download', e);

    Bus.emit(NOTIFICATION.ERROR, {
      message: `${filename} ফাইল ডাউনলোডে সমস্যা হয়েছে`,
    });
  }
}

export async function uploadFile(
  file: File,
  filePath: string,
  updateProgress: (progress: number) => void,
) : Promise<string> {
  try {
    const url = getFileSignedUrl({
      fileName: filePath,
      method: 'put',
      size: file.size,
    });

    const signedUrlResp = await axios.get<{ signedUrl: string }>(url);
    const { signedUrl } = signedUrlResp.data;

    const axiosInstance = axios.create();
    delete axiosInstance.defaults.headers.common.Authorization;

    const config: AxiosRequestConfig = {
      onUploadProgress: (e: AxiosProgressEvent) => {
        const progress = (e.loaded / (e.total || 1)) * 100;
        updateProgress(progress);
      },
    };

    await axiosInstance.put(signedUrl, file, config);

    return filePath;
  } catch (error) {
    const errMsg = `Error uploading file ${file.name}`;
    console.error(`${errMsg}:`, error);

    Bus.emit(NOTIFICATION.ERROR, {
      message: errMsg,
      permanent: true,
    });

    throw error;
  }
}

export function toEnNum(n: string): string {
  return n.replace(/[০-৯]/g, (d) => '০১২৩৪৫৬৭৮৯'.indexOf(d).toString());
}

export async function copyToClipboard(text: string): Promise<void> {
  const _legacyCopy = (): void => {
    const input: HTMLInputElement = window.document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.focus();
    input.select();

    document.execCommand('copy');
    document.body.removeChild(input);
  };

  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    _legacyCopy();
  }
}

export function getGeneratedSlug(text: string): string {
  return text
    .trim()
    .replace(/[^a-zA-Z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-')
    .toLowerCase();
}
