/* eslint-disable import/order */
import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  CONFIG,
  CONSTANTS,
  IS_DEVELOPMENT,
  hasRouteAccess,
  redirectUserAfterLogin,
} from '@/helpers';
import {
  useStore,
} from '@/store';
import Bus, {
  NOTIFICATION,
} from '@/bus';

// Auth
const AuthView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/AuthView.vue'
);
const LoginView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/LoginView.vue'
);

// Admin
const AdminTemplate = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin" */
  '@/views/Admin/AdminTemplate.vue'
);
const UserList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-user-list" */
  '@/views/Admin/UserList.vue'
);
const MutateUser = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-mutate-user" */
  '@/views/Admin/MutateUser.vue'
);
const EnrollmentList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-enrollment-list" */
  '@/views/Admin/EnrollmentList.vue'
);
const StudentList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-student-list" */
  '@/views/Admin/StudentList.vue'
);
const CouponList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-coupon-list" */
  '@/views/Admin/CouponList.vue'
);
const MutateCoupon = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-mutate-coupon" */
  '@/views/Admin/MutateCoupon.vue'
);
const HomeBannerList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-home-banner-list" */
  '@/views/Admin/HomeBannerList.vue'
);
const MutateHomeBanner = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-mutate-home-banner" */
  '@/views/Admin/MutateHomeBanner.vue'
);
const NewsList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-news-list" */
  '@/views/Admin/NewsList.vue'
);
const MutateNews = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-mutate-news" */
  '@/views/Admin/MutateNews.vue'
);

// Course Admin
const CourseList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-admin-course-list" */
  '@/views/Admin/CourseList.vue'
);
const MutateCourse = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-admin-mutate-course" */
  '@/views/Admin/MutateCourse.vue'
);
const InstructorList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-admin-instructor-list" */
  '@/views/Admin/InstructorList.vue'
);
const MutateInstructor = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-admin-mutate-instructor" */
  '@/views/Admin/MutateInstructor.vue'
);
const MaterialList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-admin-material-list" */
  '@/views/Admin/MaterialList.vue'
);
const MutateMaterial = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-admin-mutate-material" */
  '@/views/Admin/MutateMaterial.vue'
);
const SubjectList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-admin-subject-list" */
  '@/views/Admin/SubjectList.vue'
);
const MutateSubject = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-admin-mutate-subject" */
  '@/views/Admin/MutateSubject.vue'
);
const YoutubeCourseList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-admin-youtube-course-list" */
  '@/views/Admin/YoutubeCourseList.vue'
);
const MutateYoutubeCourse = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-admin-mutate-youtube-course" */
  '@/views/Admin/MutateYoutubeCourse.vue'
);
const DynamicPageList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-dynamic-page-list" */
  '@/views/Admin/DynamicPageList.vue'
);
const MutateDynamicPage = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-mutate-dynamic-page" */
  '@/views/Admin/MutateDynamicPage.vue'
);

// Individual
const UserView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "individual/user-view" */
  '@/views/Individual/UserView.vue'
);
const AccountView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "individual/user-account-view" */
  '@/views/Individual/AccountView.vue'
);

// Settings
const FacebookSettings = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "facebook-settings" */
  '@/views/Admin/Settings/FacebookSettings.vue'
);

const isGuest = {
  meta: {
    isGuest: true,
  },
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    alias: '/',
    name: 'Auth',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
        meta: {
          title: 'Login',
          ...isGuest.meta,
        },
      },
    ],
  },
  {
    path: '/user',
    name: 'User',
    component: UserView,
    children: [
      {
        path: 'account/view',
        name: 'AccountView',
        component: AccountView,
        meta: {
          title: 'Account View',
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'AdminTemplate',
    component: AdminTemplate,
    children: [
      {
        path: 'user-list',
        name: 'AdminList',
        component: UserList,
        meta: {
          title: 'Admin List',
        },
      },
      {
        path: 'user/add/:id',
        alias: 'user/edit/:id',
        name: 'MutateUser',
        component: MutateUser,
        meta: {
          title: 'Add/Edit Admin',
        },
      },
      {
        path: 'course-list',
        name: 'CourseList',
        component: CourseList,
        meta: {
          title: 'Course List',
        },
      },
      {
        path: 'course/add/:id',
        alias: 'course/edit/:id',
        name: 'MutateCourse',
        component: MutateCourse,
        meta: {
          title: 'Add/Edit Course',
        },
      },
      {
        path: 'instructor-list',
        name: 'InstructorList',
        component: InstructorList,
        meta: {
          title: 'Instructor List',
        },
      },
      {
        path: 'instructor/add/:id',
        alias: 'instructor/edit/:id',
        name: 'MutateInstructor',
        component: MutateInstructor,
        meta: {
          title: 'Add/Edit Instructor',
        },
      },
      {
        path: 'enrollment-list',
        name: 'EnrollmentList',
        component: EnrollmentList,
        meta: {
          title: 'Enrollment List',
        },
      },
      {
        path: 'student-list',
        name: 'StudentList',
        component: StudentList,
        meta: {
          title: 'Student List',
        },
      },
      {
        path: 'coupon-list',
        name: 'CouponList',
        component: CouponList,
        meta: {
          title: 'Coupon List',
        },
      },
      {
        path: 'coupon/add/:id',
        alias: 'coupon/edit/:id',
        name: 'MutateCoupon',
        component: MutateCoupon,
        meta: {
          title: 'Add/Edit Coupon',
        },
      },
      {
        path: 'home-banner-list',
        name: 'HomeBannerList',
        component: HomeBannerList,
        meta: {
          title: 'Home Banner List',
        },
      },
      {
        path: 'home-banner/add/:id',
        alias: 'home-banner/edit/:id',
        name: 'MutateHomeBanner',
        component: MutateHomeBanner,
        meta: {
          title: 'Add/Edit Home Banner',
        },
      },
      {
        path: 'subject-list',
        name: 'SubjectList',
        component: SubjectList,
        meta: {
          title: 'Subject List',
        },
      },
      {
        path: 'subject/add/:id',
        alias: 'subject/edit/:id',
        name: 'MutateSubject',
        component: MutateSubject,
        meta: {
          title: 'Add/Edit Subject',
        },
      },
      {
        path: 'youtube-course-list',
        name: 'YoutubeCourseList',
        component: YoutubeCourseList,
        meta: {
          title: 'Free Course List',
        },
      },
      {
        path: 'youtube-course/add/:id',
        alias: 'youtube-course/edit/:id',
        name: 'MutateYoutubeCourse',
        component: MutateYoutubeCourse,
        meta: {
          title: 'Add/Edit Free Course',
        },
      },
      {
        path: 'news-list',
        name: 'NewsList',
        component: NewsList,
        meta: {
          title: 'Notice List',
        },
      },
      {
        path: 'news/add/:id',
        alias: 'news/edit/:id',
        name: 'MutateNews',
        component: MutateNews,
        meta: {
          title: 'Add/Edit Notice',
        },
      },
      {
        path: 'dynamic-page-list',
        name: 'DynamicPageList',
        component: DynamicPageList,
        meta: {
          title: 'Dynamic Page List',
        },
      },
      {
        path: 'dynamic-page/add/:id',
        alias: 'dynamic-page/edit/:id',
        name: 'MutateDynamicPage',
        component: MutateDynamicPage,
        meta: {
          title: 'Add/Edit Dynamic Page',
        },
      },
      {
        path: 'material-list/:courseId',
        name: 'MaterialList',
        component: MaterialList,
        meta: {
          title: 'Material List',
        },
      },
      {
        path: 'material/add/:courseId',
        name: 'AddMaterial',
        component: MutateMaterial,
        meta: {
          title: 'Add Material',
        },
      },
      {
        path: 'material/edit/:id',
        name: 'EditMaterial',
        component: MutateMaterial,
        meta: {
          title: 'Edit Material',
        },
      },
      // settings
      {
        path: 'settings/facebook',
        name: 'FacebookSettings',
        component: FacebookSettings,
        meta: {
          title: 'Facebook Settings',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  useStore.app.prevRoutePath = from.fullPath;
  useStore.app.isLoading = true;

  // ensuring user load from local storage attempted before routing
  await useStore.app.init();
  next();
});

router.afterEach((to, from, failure) => {
  useStore.app.isLoading = false;
});

router.beforeResolve((to, from, next) => {
  const { user } = useStore.auth;

  const titleSuffix = user && IS_DEVELOPMENT
    ? ` | ${user.firstName}`
    : '';
  if (!to.meta || !to.meta.title) {
    document.title = `${CONFIG.appName}${titleSuffix}`;
  } else {
    document.title = `${to.meta.title}${titleSuffix}`;
  }

  // Redirect unauthenticated users to the login page
  if (!user) {
    if (to.fullPath !== CONSTANTS.ROUTES.GUEST_HOME) {
      next(CONSTANTS.ROUTES.GUEST_HOME);
      return;
    }
    next();
    return;
  }

  const hasAccess = hasRouteAccess(to.path, user.role);
  if (!hasAccess) {
    Bus.emit(NOTIFICATION.INFO, {
      message: `Sorry, you can't access the page ${to.path}`,
    });
  }

  const shouldRedirectToMemberHome = to.meta?.isGuest || !hasAccess || to.path === '/';

  if (shouldRedirectToMemberHome) {
    next(redirectUserAfterLogin(user).route);
    return;
  }

  // Allow authenticated users to proceed
  next();
});

export { routes };

export default router;
